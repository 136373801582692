const SaveEmail = async (email, language = "EN", t) => {
    const backendUrl = process.env.REACT_APP_BACKEND_URL;
    const { defaultSubject, defaultHtml } = getEmailData(language, t);

    const response = await fetch(`${backendUrl}/api/mail/save`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({ to: email, subject: defaultSubject, html: defaultHtml })
    });
    return response;
};

function getEmailData(language, t) {
    switch (language.toUpperCase()) {
        case "ESP":
            return {
                defaultSubject: t("EmailData.defaultSubject"),
                defaultHtml: t("EmailData.defaultHtml")
            };
        case "EN":
        default:
            return {
                defaultSubject: t("EmailData.defaultSubject"),
                defaultHtml: t("EmailData.defaultHtml")
            };
    }
}

export { SaveEmail };
